import { TokenAmount, Pair, Currency } from '@uniswap/sdk'
import { useMemo } from 'react'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { Interface } from '@ethersproject/abi'
import { useActiveWeb3React } from '../hooks'

import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}

export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId)
      ]),
    [chainId, currencies]
  )

  // console.log("usePairs currencies")
  // console.log(currencies)

  // console.log("usePairs tokens")
  // console.log(tokens)

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined
      }),
    [tokens]
  )

  // console.log("usePairs pairAddresses")
  // console.log(pairAddresses)

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  // console.log("usePairs results")
  // console.log(results)

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      // console.log("usePairs useMemo tokenA")
      // console.log(tokenA)

      // console.log("usePairs useMemo tokenB")
      // console.log(tokenB)

      // console.log("usePairs useMemo loading")
      // console.log(loading)

      // console.log("usePairs useMemo !tokenA || !tokenB || tokenA.equals(tokenB)")
      // console.log(!tokenA || !tokenB || tokenA.equals(tokenB))

      // console.log("usePairs useMemo tokenA.equals(tokenB)")
      // console.log(tokenB ? tokenA?.equals(tokenB) : false)

      // console.log("usePairs useMemo reserves")
      // console.log(reserves)

      // console.log("usePairs useMemo !reserves")
      // console.log(!reserves)

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves

      // console.log("usePairs useMemo reserve0")
      // console.log(reserve0)

      // console.log("usePairs useMemo reserve1")
      // console.log(reserve1)

      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

      // console.log("usePairs useMemo token0")
      // console.log(token0)

      // console.log("usePairs useMemo token1")
      // console.log(token1)

      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
      ]
    })
  }, [results, tokens])
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]])[0]
}
